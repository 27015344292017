
export default {
    data() {
        return {
            includes(s,v){
                return s && s.includes ? s.includes(v) : false;
            }
        }
    },
    computed : {
        link(){
            return this.blok.link.cached_url ? this.blok.link.cached_url : this.blok.link;
        }
    },
    props: ['blok']

}
